import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

const ButtonContainer = ({ children }: Props): JSX.Element => (
  <div className="my-[30px] flex justify-between">{children}</div>
);

export default ButtonContainer;
